import { useCallback, useEffect, useState } from "react";

import { Card, Divider, Grid, List, ListItem, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import CustomViewField from "../../components/CustomViewField";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";

dayjs.extend(utc);

const types = {
  vacation: "Отпуск",
  day_off: "Отгул",
  sick_leave: "Больничный",
  overtime_work_days: "Сверхурочные часы",
  fired: "Увольнение",
  working_holidays: "Работа в праздники/выходные",
  promotion: "Поощрение",
};

const statuses = {
  new: { label: "Новое", color: "#414bb2" },
  approved: { label: "Согласовано", color: "#8fd14f" },
  rejected: { label: "Отклонено", color: "#f24726" },
  cancelled: { label: "Аннулировано", color: "#f24726" },
  recalled: { label: "Отозвано", color: "#f24726" },
  draft: { label: "Черновик", color: "#828282" },
};

const utcOffset = dayjs().utcOffset();

const MyPromotionView = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const { myPromotionId } = useParams();
  const { myPromotion } = useSelector((state) => state.calendarReducer);
  const { setMyPromotion } = useActions();

  const handleBack = () => {
    navigate(`/schedule/my-promotions`);
  };

  const getHistory = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/calendar_rules/history/all/?calendar_rules_id=${myPromotionId}`,
      );
      const data = response.data.items;
      setHistory(data);
    } catch (e) {
      console.log(e);
    }
  }, [myPromotionId]);

  const getMyPromotion = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(`/calendar_rules/${myPromotionId}/`);
      const data = response.data;
      setMyPromotion(data);
    } catch (e) {
      console.log(e);
    }
  }, [setMyPromotion, myPromotionId]);

  useEffect(() => {
    Promise.all([getMyPromotion(), getHistory()]).then(() => setLoading(false));
  }, [getHistory, getMyPromotion]);

  return (
    <Card sx={{ mt: 2, p: 3 }}>
      {loading && <LoadingIndicator />}
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Typography sx={{ pb: 2 }} variant="h6">
            Информация:
          </Typography>
          <CustomViewField label="Тип заявки" text={types[myPromotion?.type]} />
          <CustomViewField
            label="Дата создания"
            text={dayjs(myPromotion?.created_at).format("DD.MM.YYYY")}
          />
          <CustomViewField
            label="Сотрудник"
            text={myPromotion?.users && myPromotion?.users[0]?.display_name}
          />
          <CustomViewField label="Отдел" text={myPromotion?.department?.name} />
          <CustomViewField
            label="Причина поощрения"
            text={myPromotion?.reason}
          />
          <CustomViewField
            label="Документ-основание"
            text={myPromotion?.supporting_document}
          />
          <CustomViewField label="Проект" text={myPromotion?.project?.code} />
          <CustomViewField
            label="Дата начала"
            text={dayjs(myPromotion?.start_date).format("DD.MM.YYYY")}
          />
          <CustomViewField
            label="Дата окончания"
            text={dayjs(myPromotion?.end_date).format("DD.MM.YYYY")}
          />
          <CustomViewField label="Коэффициент" text={myPromotion?.rate} />
          <CustomViewField
            label="Текст запроса"
            text={myPromotion?.request_text}
          />
          <CustomViewField
            label="Количество отгулов"
            text={myPromotion?.amount}
          />
          <CustomViewField label="Комментарий" text={myPromotion?.comment} />
          <Divider sx={{ mb: 3 }} />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography sx={{ pb: 2 }} variant="h6">
            История:
          </Typography>
          <Stack>
            {history.map((i) => (
              <List key={i.updated_at} sx={{ listStyleType: "disc" }}>
                <ListItem
                  sx={[
                    { display: "list-item" },
                    { "&::marker": { color: statuses[i.status]?.color } },
                  ]}
                >
                  <Stack>
                    <Typography variant="body2">
                      {dayjs(i.updated_at)
                        .add(utcOffset, "m")
                        .format("DD.MM.YYYY HH:mm")}
                    </Typography>
                    <Typography sx={{ pb: 1 }}>
                      {i.user_approved?.display_name}
                    </Typography>
                    <Typography sx={{ color: statuses[i.status]?.color }}>
                      {statuses[i.status]?.label}
                    </Typography>
                    {i.comment && (
                      <Typography sx={{ pt: 1 }}>
                        Комментарий: {i.comment}
                      </Typography>
                    )}
                  </Stack>
                </ListItem>
              </List>
            ))}
          </Stack>
        </Grid>
      </Grid>
      <Stack direction="row" spacing={2}>
        <Button color="primary" onClick={handleBack} variant="text">
          Назад
        </Button>
      </Stack>
    </Card>
  );
};

export default MyPromotionView;
