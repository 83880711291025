import { useCallback, useEffect, useState } from "react";

import { Card, Divider, Grid, List, ListItem, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import CustomViewField from "../../components/CustomViewField";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomTextFieldNew from "../../components/TextFields/CustomTextFieldNew";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import { TitleStack } from "../../theme/standarts_styles";

const utcOffset = dayjs().utcOffset();

const MyCompensationView = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [history, setHistory] = useState([]);
  const { myCompensationId } = useParams();
  const { myCompensation } = useSelector((state) => state.paymentsReducer);
  const { setMyCompensation } = useActions();

  const methods = useForm({
    mode: "onBlur",
  });

  const {
    handleSubmit,
    setError,
    formState: { isSubmitSuccessful },
  } = methods;

  const getColorByStatus = (value) => {
    switch (value) {
      case "Новое":
        return "#414bb2";
      case "Согласовано":
      case "Выплачено":
        return "#8fd14f";
      case "Отклонено":
      case "Аннулировано":
        return "#f24726";
      case "Отозвано":
      case "Черновик":
      case "Черновик (Изменено)":
        return "#828282";
      default:
        return "#000000";
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      if (cancelConfirm) {
        await $authHost.post(`/compensation/${myCompensationId}/cancel/`, {
          comment: data.comment,
        });
      }
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/payments/my-compensations");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleBack = () => {
    navigate(`/payments/my-compensations`);
  };

  const handleEdit = () => {
    navigate(`/payments/my-compensations/${myCompensationId}/edit`);
  };

  const getHistory = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/compensation/history/all/?compensation_id=${myCompensationId}`,
      );
      const data = response.data.items;
      setHistory(data);
    } catch (e) {
      console.log(e);
    }
  }, [myCompensationId]);

  const getMyCompensation = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/compensation/${myCompensationId}/`,
      );
      const data = response.data;
      setMyCompensation(data);
    } catch (e) {
      console.log(e);
    }
  }, [setMyCompensation, myCompensationId]);

  useEffect(() => {
    Promise.all([getMyCompensation(), getHistory()]).then(() =>
      setLoading(false),
    );
  }, [getHistory, getMyCompensation]);

  return (
    <>
      <TitleStack>
        <Typography sx={{ my: 2 }} variant="h5">
          Заявка
        </Typography>
      </TitleStack>
      <Card sx={{ p: 3 }}>
        {loading && <LoadingIndicator />}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Typography sx={{ pb: 2 }} variant="h6">
                  Информация:
                </Typography>
                <CustomViewField
                  label="Вид компенсации"
                  text={myCompensation?.type}
                />
                <CustomViewField
                  label="Сотрудник"
                  text={myCompensation?.user?.display_name}
                />
                <CustomViewField
                  label="Отдел"
                  text={myCompensation?.department?.name}
                />
                <CustomViewField
                  label="Валюта"
                  text={myCompensation?.currency}
                />
                <CustomViewField
                  label="Сумма в валюте"
                  text={myCompensation?.amount}
                />
                <CustomViewField
                  label="Период расходов"
                  text={
                    myCompensation?.expenses_period
                      ? dayjs(myCompensation?.expenses_period).format(
                          "MMMM YYYY",
                        )
                      : ""
                  }
                />
                <CustomViewField
                  label="Период выплаты"
                  text={
                    myCompensation?.payment_period
                      ? dayjs(myCompensation?.payment_period).format(
                          "MMMM YYYY",
                        )
                      : ""
                  }
                />
                <CustomViewField
                  files={myCompensation?.checks}
                  label="Чек"
                  type="file"
                />
                <CustomViewField
                  files={myCompensation?.photos}
                  label="Фото-отчет"
                  type="file"
                />
                <CustomViewField
                  label="Текст запроса"
                  text={myCompensation?.request_text}
                />
                <Divider sx={{ mb: 3 }} />
                {(myCompensation?.status === "Новое" ||
                  myCompensation?.status === "Черновик") &&
                  cancelConfirm && (
                    <CustomTextFieldNew
                      label="Комментарий"
                      multiline
                      name="comment"
                      required
                      rows={3}
                    />
                  )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography sx={{ pb: 2 }} variant="h6">
                  История:
                </Typography>
                <Stack>
                  {history.map((i) => (
                    <List key={i.updated_at} sx={{ listStyleType: "disc" }}>
                      <ListItem
                        sx={[
                          { display: "list-item" },
                          {
                            "&::marker": { color: getColorByStatus(i.status) },
                          },
                        ]}
                      >
                        <Stack>
                          <Typography variant="body2">
                            {dayjs(i.updated_at)
                              .add(utcOffset, "m")
                              .format("DD.MM.YYYY HH:mm")}
                          </Typography>
                          <Typography sx={{ pb: 1 }}>
                            {i.user_approved?.display_name}
                          </Typography>
                          <Typography
                            sx={{ color: getColorByStatus(i.status) }}
                          >
                            {i.status}
                          </Typography>
                          {i.comment && (
                            <Typography sx={{ pt: 1 }}>
                              Комментарий: {i.comment}
                            </Typography>
                          )}
                        </Stack>
                      </ListItem>
                    </List>
                  ))}
                </Stack>
              </Grid>
            </Grid>
            <Stack direction="row" spacing={2}>
              {cancelConfirm ? (
                <>
                  <Button
                    onClick={() => setCancelConfirm(false)}
                    variant="contained"
                  >
                    Нет
                  </Button>
                  <Button color="primary" type="submit" variant="text">
                    Да
                  </Button>
                </>
              ) : (
                <>
                  {myCompensation?.status === "Черновик" && (
                    <Button onClick={handleEdit} variant="contained">
                      Редактировать
                    </Button>
                  )}
                  {(myCompensation?.status === "Новое" ||
                    myCompensation?.status === "Черновик") && (
                    <Button
                      onClick={() => setCancelConfirm(true)}
                      variant="outlined"
                    >
                      Отозвать
                    </Button>
                  )}
                  <Button color="primary" onClick={handleBack} variant="text">
                    Назад
                  </Button>
                </>
              )}
            </Stack>
            {cancelConfirm && (
              <Typography sx={{ pt: 2 }}>
                Вы уверены, что хотите аннулировать заявление?
              </Typography>
            )}
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default MyCompensationView;
