import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isWarningModalOpen: false,
  isInitAuth: false,
  userId: null,
  display_name: null,
  token: null,
  refreshToken: null,
  tokenLifetime: null,
  id_token: null,
  idTokenLifetime: null,
  email: null,
  startTime: null,
  name: null,
  roles: null,
  status: null,
  locked_flags: null,
  birth_date: null,
  first_name: null,
  last_name: null,
  phone: null,
  country: null,
  street_address: null,
  about_me: null,
  photo: null,
  work_schedule: null,
  communication_channels: null,
  myContracts: [],
  selectedMyContract: null,
  source_id: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    logIn(state, action) {
      const {
        display_name,
        first_name,
        last_name,
        email,
        phone,
        birth_date,
        about_me,
        country,
        photo,
        street_address,
        work_schedule,
        communication_channels,
        startTime,
        groups,
        source_id,
      } = action.payload;
      return {
        ...state,
        display_name,
        first_name,
        last_name,
        email,
        phone,
        birth_date,
        about_me,
        country,
        photo,
        street_address,
        work_schedule,
        communication_channels,
        startTime,
        roles: groups,
        isInitAuth: true,
        source_id,
      };
    },
    setToken(state, { payload }) {
      state.token = payload;
    },
    setRefreshToken(state, { payload }) {
      state.refreshToken = payload;
    },
    setTokenLifetime(state, { payload }) {
      state.tokenLifetime = payload;
    },
    setIdTokenLifetime(state, { payload }) {
      state.idTokenLifetime = payload;
    },
    setIdToken(state, { payload }) {
      state.id_token = payload;
    },
    setStartTime(state, { payload }) {
      state.startTime = payload;
    },
    updateUser(state, action) {
      const {
        display_name,
        first_name,
        last_name,
        email,
        phone,
        birth_date,
        about_me,
        country,
        photo,
        street_address,
        work_schedule,
        communication_channels,
        groups,
      } = action.payload;
      return {
        ...state,
        display_name,
        first_name,
        last_name,
        email,
        phone,
        birth_date,
        about_me,
        country,
        photo,
        street_address,
        work_schedule,
        communication_channels,
        roles: groups,
      };
    },
    setWarningModalOpen(state, { payload }) {
      state.isWarningModalOpen = payload;
    },
    refreshToken(state, { payload }) {
      state.token = payload.token;
      state.startTime = payload.startTime;
      // state.id_token = payload.id_token;
    },
    setMyContracts(state, { payload }) {
      state.myContracts = payload;
    },
    setSelectedMyContact(state, { payload }) {
      const index = state.myContracts.findIndex(
        (contract) => contract.id === payload,
      );
      state.selectedMyContract = state.myContracts[index];
    },
  },
});

export const { actions: authActions, reducer: authReducer } = authSlice;
export default authSlice.reducer;
