import { useCallback, useEffect, useState } from "react";

import { Avatar, Card, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Label from "../../components/label/Label";
import MaterialTablePagination from "../../components/MaterialTablePagination";
import { TableCellsWrap } from "../../components/TableCellsWrap";
import { statuses } from "../../constants";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import { getSortingString } from "../../utils/getSortingString";

const MyPromotionsList = () => {
  const tableId = "Promotions";
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setPromotions } = useActions();
  const { promotions } = useSelector((state) => state.calendarReducer);
  const { source_id, display_name } = useSelector((state) => state.authReducer);
  const [userBudget, setUserBudget] = useState(null);

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [columnFilters, setColumnFilters] = useState(
    tableStates[tableId]?.columnFilters || [],
  );
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const getColorByStatus = (renderedCellValue) => {
    switch (renderedCellValue) {
      case "approved":
        return "success";
      case "rejected":
        return "error";
      case "new":
        return "info";
      default:
        return "default";
    }
  };

  const types = {
    vacation: "Отпуск",
    day_off: "Отгул",
    sick_leave: "Больничный",
    overtime_work_days: "Сверхурочные часы",
    fired: "Увольнение",
    working_holidays: "Работа в праздники/выходные",
    promotion: "Поощрение",
  };
  const tableColumns = [
    {
      accessorKey: "updated_at",
      header: "Последние изменения",
      accessorFn: (row) => dayjs(row.updated_at).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "status",
      header: "Статус",
      accessorFn: (row) => statuses[row.status] || "",
      Cell: ({ renderedCellValue, row }) => {
        return (
          <TableCellsWrap>
            {renderedCellValue ? (
              <Label color={getColorByStatus(row.original.status)}>
                {renderedCellValue}
              </Label>
            ) : (
              <Label color="default">Не указано</Label>
            )}
          </TableCellsWrap>
        );
      },
    },
    {
      accessorKey: "type",
      header: "Тип заявления",
      accessorFn: (row) => types[row.type] || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "users.display_name",
      header: "Сотрудник",
      accessorFn: (row) =>
        row.users
          .map(
            (user) =>
              user?.display_name || `${user?.first_name} ${user?.last_name}`,
          )
          .join(", "),
      Cell: ({ renderedCellValue, row }) => {
        if (Array.isArray(row.original?.users)) {
          return (
            <TableCellsWrap>
              <Stack alignItems="flex-start" direction="column" spacing={1}>
                {row.original.users.map((user) => (
                  <Chip
                    avatar={<Avatar src={user?.photo} />}
                    key={user.source_id}
                    label={
                      user?.display_name ||
                      `${user?.first_name} ${user?.last_name}`
                    }
                    variant="outlined"
                  />
                ))}
              </Stack>
            </TableCellsWrap>
          );
        }
      },
    },
    {
      id: "department.name",
      header: "Отдел",
      accessorFn: (row) => row.department?.name || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "reason",
      header: "Причина поощрения",
      accessorFn: (row) => row.reason || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "supporting_document",
      header: "Документ-основание",
      accessorFn: (row) => row.supporting_document || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "project.code",
      header: "Проект",
      accessorFn: (row) => row.project?.code || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "start_date",
      header: "Дата начала",
      accessorFn: (row) => dayjs(row.start_date).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "end_date",
      header: "Дата окончания",
      accessorFn: (row) => row,
      Cell: ({ renderedCellValue }) => {
        switch (renderedCellValue.type) {
          case "fired": {
            return <TableCellsWrap>{""}</TableCellsWrap>;
          }
          default:
            return (
              <TableCellsWrap>
                {dayjs(renderedCellValue.end_date).format("DD.MM.YYYY") || ""}
              </TableCellsWrap>
            );
        }
      },
    },
    {
      id: "rate",
      header: "Коэффициент",
      accessorFn: (row) => row.rate || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "request_text",
      header: "Текст запроса",
      accessorFn: (row) => row.request_text || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "overwork_deposit",
      header: "Начисления",
      accessorFn: (row) => row.overwork_deposit || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "overwork_withdrawal",
      header: "Списания",
      accessorFn: (row) => row.overwork_withdrawal || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "user_approved.display_name",
      header: "Кто решил",
      accessorFn: (row) =>
        row.user_approved?.display_name ||
        `${row.user_approved?.first_name} ${row.user_approved?.last_name}`,
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>
          <Chip
            avatar={
              <Avatar
                alt={
                  row.original.user_approved?.display_name.substring(0, 1) ||
                  (row.original.user_approved &&
                    row.original.user_approved?.first_name.substring(0, 1)) ||
                  null
                }
                src={row.original.user_approved?.photo}
              />
            }
            label={
              row.original.user_approved?.display_name ||
              (row.original.user_approved &&
                `${row.original.user_approved?.first_name} ${row.original.user_approved?.last_name}`) ||
              null
            }
            variant="outlined"
          />
        </TableCellsWrap>
      ),
    },
    {
      id: "overwork_budget",
      header: "Бюджет переработок",
      accessorFn: (row) => row?.overwork_budget || "",
      Cell: ({ renderedCellValue }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "created_at",
      header: "Дата подачи",
      accessorFn: (row) => dayjs(row.created_at).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
  ];

  const handleOpenViewPage = (row) => {
    const id = row.original.id;
    if (id) {
      navigate(`/schedule/my-promotions/${id}`);
    }
  };

  const loadData = useCallback(async () => {
    setLoading(true);

    try {
      const sortStr = getSortingString(sorting);

      const response = await $authHost.get(`/calendar_rules/promotion/me/`, {
        params: {
          page: pagination.pageIndex + 1,
          size: pagination.pageSize,
          ...(searchText && { search: searchText }),
          ...(sortStr && { sort_by: sortStr }),
          ...columnFilters.reduce(
            (acc, column) => ({
              ...acc,
              ...{ [`${column.id}_text`]: column.value },
            }),
            {},
          ),
        },
      });

      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
      setPromotions(response.data.items);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    setPromotions,
    sorting,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const getUserBudget = useCallback(async () => {
    try {
      const response = await $authHost.get(
        `/calendar_rules/promotion/overwork_budget/`,
        {
          params: {
            user_id: source_id,
          },
        },
      );
      setUserBudget(response.data);
    } catch (e) {
      console.log(e);
    }
  }, [source_id, setUserBudget]);

  useEffect(() => {
    getUserBudget();
  }, [getUserBudget]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  return (
    <Card sx={{ pt: 1, mt: 2 }}>
      <MaterialTablePagination
        columnFilters={columnFilters}
        columns={tableColumns}
        data={promotions}
        enableHiding={true}
        id={tableId}
        initialState={{
          columnVisibility: {
            "project.code": false,
          },
        }}
        loading={loading}
        muiTableBodyCellProps={({ row }) => ({
          onClick: () => {
            handleOpenViewPage(row);
          },
          sx: {
            cursor: "pointer",
          },
        })}
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={onGlobalFilterChange}
        onSortingChange={setSorting}
        pageCount={pageCount}
        pagination={pagination}
        rowCount={rowCount}
        search={searchText}
        setPagination={setPagination}
        sorting={sorting}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        pb={2}
        px={2}
        spacing={2}
      >
        <Stack direction="row" spacing={4}>
          <Typography>Сотрудник: {display_name}</Typography>
          <Typography>
            Начисления: {userBudget ? userBudget?.overwork_deposit : "0"}
          </Typography>
          <Typography>
            Списания: {userBudget ? userBudget?.overwork_withdrawal : "0"}
          </Typography>
        </Stack>
        <Typography>
          Бюджет переработок: {userBudget ? userBudget?.overwork_budget : "0"}
        </Typography>
      </Stack>
    </Card>
  );
};

export default MyPromotionsList;
