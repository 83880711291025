import React, { useCallback, useEffect, useMemo, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import BlockFormModal from "../BlockFormModal";
import SkeletonInput from "../SkeletonInput";

const PlanningEdit = ({ handleClose, loadDataPlans, planId }) => {
  const [loading, setLoading] = useState(false);

  const { setPlan } = useActions();
  const isMobile = useResponsive("down", "md");

  const { plan } = useSelector((state) => state.planningReducer);
  const { project } = useSelector((state) => state.projectsReducer);

  const loadPlan = useCallback(() => {
    setLoading(true);
    try {
      const getPlan = async () => {
        const response = await $authHost.get(`/calendar_entries/${planId}/`);
        setPlan(response.data);
        setLoading(false);
      };
      getPlan();
    } catch (e) {
      console.log(e);
    }
  }, [planId, setPlan]);

  useEffect(() => {
    planId && loadPlan();
  }, [loadPlan, planId]);

  const methods = useForm({
    mode: "onBlur",
    values: plan,
  });

  const {
    formState: { errors, isDirty, isSubmitSuccessful },
    handleSubmit,
    control,
    register,
    reset,
  } = methods;

  useEffect(() => {
    reset(plan);
  }, [plan, reset]);

  const onSubmit = async (data) => {
    setLoading(true);

    const { user, time_estimated, start_date, end_date } = data;

    const newData = {
      time_estimated,
      user_id: user?.email,
      start_date: dayjs(start_date).format("YYYY-MM-DDTHH:mm:ss"),
      end_date: dayjs(end_date).format("YYYY-MM-DDTHH:mm:ss"),
    };

    try {
      const response = await $authHost.put(
        `/calendar_entries/${plan?.id}/update/`,
        newData,
      );
      setLoading(false);
      handleClose();
      loadDataPlans();
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Card sx={{ p: 3, mb: 2 }}>
          {isMobile && (
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="space-between"
              sx={{ mb: 3 }}
            >
              <Grid item>
                <Typography color="text.secondary" variant="subtitle1">
                  Редактировать
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleClose} sx={{ p: 0 }}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              alignItems="flex-start"
              container
              direction="column"
              justifyContent="space-between"
            >
              <SkeletonInput loading={loading}>
                <Controller
                  control={control}
                  name="user"
                  render={({
                    field: { onChange, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option.display_name}
                        onChange={(e, value) => onChange(value)}
                        options={project?.team}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!error}
                            helperText={error?.message}
                            label="Сотрудник"
                            size="small"
                          />
                        )}
                        sx={{
                          mb: 2,
                          width: "100%",
                        }}
                      />
                    );
                  }}
                  rules={
                    {
                      // required: 'Обязательное поле',
                    }
                  }
                />
              </SkeletonInput>
              <SkeletonInput loading={loading}>
                <TextField
                  label="Плановая нагрузка"
                  {...register("time_estimated", {
                    // required: 'Обязательное поле',
                    pattern: {
                      value: /^\d*$/,
                      message: "Некорректный ввод",
                    },
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">h</InputAdornment>
                    ),
                  }}
                  error={!!errors?.time_estimated}
                  fullWidth
                  helperText={errors?.time_estimated?.message}
                  size="small"
                  sx={{
                    mb: 2,
                  }}
                />
              </SkeletonInput>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <SkeletonInput loading={loading}>
                    <Controller
                      control={control}
                      name="start_date"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            control={control}
                            label="Дата начала"
                            onChange={(e) => {
                              onChange(e);
                            }}
                            slotProps={{
                              textField: {
                                error: !!error,
                                helperText: error?.message,
                                size: "small",
                              },
                            }}
                            sx={{
                              width: "100%",
                              mb: 2,
                            }}
                            value={dayjs(value)}
                          />
                        </LocalizationProvider>
                      )}
                      rules={
                        {
                          // required: 'Обязательное поле',
                        }
                      }
                    />
                  </SkeletonInput>
                </Grid>
                <Grid item md={6}>
                  <SkeletonInput loading={loading}>
                    <Controller
                      control={control}
                      name="end_date"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            control={control}
                            label="Дата конца"
                            onChange={(e) => {
                              onChange(e);
                            }}
                            slotProps={{
                              textField: {
                                error: !!error,
                                helperText: error?.message,
                                size: "small",
                              },
                            }}
                            sx={{
                              width: "100%",
                              mb: 2,
                            }}
                            value={dayjs(value)}
                          />
                        </LocalizationProvider>
                      )}
                      rules={
                        {
                          // required: 'Обязательное поле',
                        }
                      }
                    />
                  </SkeletonInput>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ width: "100%", mb: 2 }}>
              {isMobile ? (
                <Stack
                  alignItems="center"
                  direction="column"
                  justifyContent="center"
                  spacing={2}
                  sx={{ width: "100%", mt: 3 }}
                >
                  <Button
                    disabled={loading}
                    sx={{ width: "100%" }}
                    type="submit"
                    variant="contained"
                  >
                    Сохранить
                  </Button>
                  <Button
                    disabled={loading}
                    onClick={() => reset()}
                    sx={{ width: "100%" }}
                    variant="outlined"
                  >
                    Сбросить
                  </Button>
                </Stack>
              ) : (
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      disabled={loading}
                      sx={{ mr: 2 }}
                      type="submit"
                      variant="contained"
                    >
                      Сохранить
                    </Button>
                    <Button onClick={handleClose} variant="text">
                      Закрыть
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={loading}
                      onClick={() => reset()}
                      variant="text"
                    >
                      Сбросить
                    </Button>
                  </Grid>
                </Stack>
              )}
            </Grid>
          </form>
        </Card>
      </Grid>
      <BlockFormModal when={isDirty && !isSubmitSuccessful} />
    </Grid>
  );
};

export default PlanningEdit;
